import React from 'react';
import Home from '../Home';
import Economy from '../Economy';
import HowWorks from '../HowWorks';
import Motivation from '../Motivation';
import MotivationTwo from '../MotivationTwo';
import Simulation from '../Simulation';
import Benefits from '../Benefits';
import Faqs from '../Faqs';
import WhoWeAreMobile from '../WhoWeAreMobile';
import WhoWeAreNormal from '../WhoWeAreNormal';
import Footer from '../Footer';
import HeaderMenu from '../../components/header';
import Fab from '../../components/Fab';

const SinglePage: React.FC = () => (
  <div className="container-fluid" id="home">
    <HeaderMenu isInHome />
    <Home />
    <Economy />
    <HowWorks />
    <Motivation />
    <div className="hiddem-normal">
      <WhoWeAreMobile />
    </div>
    <div className="hiddem-mobile">
      <WhoWeAreNormal />
    </div>
    <MotivationTwo />
    <div className="hiddem-mobile">
      <Simulation />
    </div>
    <Benefits />
    <Faqs />
    <Footer />
    <Fab />
  </div>
);

export default SinglePage;
