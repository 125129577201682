import React from 'react';

import {
  Container, Content,
} from './styles';

import missao from '../../assets/novas/missao.png';
import visao from '../../assets/novas/visao.png';
import valores from '../../assets/novas/valores.png';

const Home: React.FC = () => (
  <>
    <Container id="whowearem">
      <Content>
        <div className="container-fluid" style={{ padding: 5 }}>
          <div className="row">
            <div
              className="col-6"
              style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 30, textAlign: 'justify',
              }}
            >
              <img src={missao} alt="img" style={{ width: 140 }} />
              <h1>Missão</h1>
              <p style={{ color: 'black' }}>
                Desenvolver soluções alternativas de energias,
                de forma sustentável, afim de gerar resultados
                para clientes, colaboradores, parceiros, sócios
                e investidores.
              </p>
              <img src={visao} alt="img" style={{ width: 140 }} />
              <h1>Visão</h1>
              <p style={{ color: 'black' }}>
                Ser empresa referência no mercado de
                geração de energia.
              </p>
              <img src={valores} alt="img" style={{ width: 140 }} />
              <h1>Valores</h1>
              <p style={{ marginTop: 8, textAlign: 'left', color: 'black' }}>
                <ul>
                  <li>
                    Honestidade e Caráter
                  </li>
                  <li>
                    Compromisso e Respeito
                  </li>
                  <li>
                    Sustentabilidade
                  </li>
                  <li>
                    Inovação
                  </li>
                  <li>
                    Foco nos resultados
                  </li>
                </ul>
              </p>
            </div>
            <div
              className="col-6"
              style={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30,
              }}
            >
              <h1 style={{
                color: '#2290C9', fontWeight: 'bold', textAlign: 'right',
              }}
              >
                QUEM SOMOS ?
              </h1>
              <div style={{
                fontSize: '20px', color: 'white', textAlign: 'justify', textIndent: 100,
              }}
              >
                <p>
                  Somos uma empresa Norte Mineira com sede
                  em Montes Claros. Situamos na região que é o
                  coração da geração solar do estado de Minas
                  Gerais, número 1 em Geração distribuída (GD) do
                  Brasil.
                </p>
                <p>
                  Em 2017 iniciamos nossas atividades com o
                  objetivo de fornecer soluções alternativas de
                  energia por meio de fontes limpas e renováveis, a
                  fim de proporcionar liberdade de mercado,
                  desenvolvimento sustentável e redução de custos
                  para empresas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  </>
);

export default Home;
