import React from 'react';

import {
  Container, Content, Place, VideoArea,
} from './styles';

import Button from '../../components/button';

const Home: React.FC = () => (
  <div>
    <div id="howWorks" />
    <Container className="container-fluid">
      <Content>
        <h1>COMO FUNCIONA?</h1>
        <Place>
          <div>
            <a href="#simulation">
              <Button>QUERO ECONOMIZAR</Button>
            </a>
          </div>
        </Place>
      </Content>
    </Container>
    <VideoArea>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/C3K8XGrcR_U?controls=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer;
          autoplay;
          clipboard-write;
          encrypted-media;
          gyroscope;
          picture-in-picture"
        allowFullScreen
      />
    </VideoArea>
  </div>
);

export default Home;
