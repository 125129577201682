import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
    display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    margin-bottom: 30px;
    text-align: left;
    font-weight: bold;
    font-size: 35px;
    color: #2290C9;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  animation: ${appearFromRight} 1s;
  border-radius: 10px;
  padding: 30px;

  form {
    padding: 30px;
    text-align: center;
    background-color: rgb(230, 230, 230, 0.9);
    border-radius: 30px;
    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
`;
