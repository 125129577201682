import React, {
  useCallback, useState, CSSProperties,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import logoImg from '../../assets/logo.png';
import Button from '../button';
import { Image } from './styles';
import Consts from '../../utils/consts';

const style: CSSProperties = {
  color: '#5c5c5c',
  marginLeft: 20,
  fontWeight: 'bold',
  textDecoration: 'none',
  fontSize: '10px',
  marginTop: '35px',
  cursor: 'pointer',
};

interface HeaderMenuProps {
  isInHome?: boolean;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ isInHome = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleRollTo = useCallback((link: string) => {
    if (!isInHome) {
      history.push('/');

      setTimeout(() => {
        window.location.href = link;

        setTimeout(() => {
          window.scroll(0, window.scrollY - 90);
        }, 800);
      }, 800);

      return;
    }

    if (window.location.href.indexOf(link) < 0) {
      window.location.href = link;

      setTimeout(() => {
        window.scroll(0, window.scrollY - 90);
      }, 800);
    } else {
      window.location.href = link;

      setTimeout(() => {
        window.scroll(0, window.scrollY - 90);
      }, 300);
    }
  }, [history, isInHome]);

  return (
    <div>
      <Navbar light fixed="top" expand="md" style={{ backgroundColor: 'rgb(255,255,255, 0.8)' }}>
        <NavbarBrand href="#lnk-#home" onClick={() => { handleRollTo('#home'); }}>
          <Image src={logoImg} alt="EAB Energias" />
        </NavbarBrand>
        <Button
          className={`navbar-toggler ${isOpen ? '' : 'collapsed'}`}
          onClick={toggle}
          style={{
            backgroundColor: '#c6c6c6', borderRadius: '10px', marginRight: '55px',
          }}
        >
          <span className="navbar-toggler-icon" />
        </Button>
        <Collapse isOpen={isOpen} toggle={toggle} navbar>
          <div style={{ flex: 1 }} />
          <Nav className="mr-auto" navbar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <NavItem className="hiddem-mobile">
              <NavLink style={{ ...style }} onClick={() => { handleRollTo('#whowearen'); }}>SOBRE NÓS</NavLink>
            </NavItem>
            <NavItem className="hiddem-normal">
              <NavLink style={{ ...style }} onClick={() => { handleRollTo('#whowearem'); }}>SOBRE NÓS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ ...style }} onClick={() => { handleRollTo('#ourFarms'); }}>NOSSAS FAZENDAS SOLARES</NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ ...style }} onClick={() => { handleRollTo('#howWorks'); }}>COMO FUNCIONA?</NavLink>
            </NavItem>
            <NavItem>
              <NavLink style={{ ...style }} onClick={() => { handleRollTo('#faqs'); }}>DÚVIDAS FREQUENTES</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={Consts.customerUrl} style={{ ...style }}>ÁREA DO CLIENTE</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => { handleRollTo('#simulation'); }}>
                <Button style={{
                  fontSize: 10, marginRight: '15px', marginLeft: 15, height: 30, marginTop: 25,
                }}
                >
                  CALCULE SUA ECONOMIA
                </Button>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default HeaderMenu;
