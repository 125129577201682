import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

import {
  Container, Content, Place, Background, BackgroundMobile,
} from './styles';

import YellowButton from '../../components/yellowButton';
import UsinasImg from '../../assets/usinaslide.png';

const Motivation: React.FC = () => (
  <Container id="ourFarms">
    <Content>
      <h1>
        ESTAMOS CONSTRUINDO
        <br />
        +19 NOVAS USINAS
      </h1>
      <Place>
        <YellowButton style={{ width: '280px', fontSize: 14 }} onClick={() => { window.location.href = '/whoweare'; }}>
          SAIBA MAIS
          {' '}
          <FiArrowRight size={30} color="#2290C9" />
        </YellowButton>
      </Place>
    </Content>
    <Background>
      <img className="img-fluid" src={UsinasImg} alt="img" />
    </Background>
    <BackgroundMobile>
      <img className="img-fluid" src={UsinasImg} alt="img" />
    </BackgroundMobile>
  </Container>
);

export default Motivation;
