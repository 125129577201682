import styled from 'styled-components';

export const Header = styled.div`
  background-color: rgb(255,255,255, 0.6);
`;

export const Image = styled.img`
  margin-left: 50px;
  max-width: 200px;
`;
