import { shade } from 'polished';
import styled, { keyframes } from 'styled-components';

import singUpBackgroundImg from '../../assets/banner.png';

export const Container = styled.div`
    margin-top: 93px;

    background: url(${singUpBackgroundImg}) no-repeat center;

    @media (max-width: 780px) {
      background: url(${singUpBackgroundImg}) no-repeat;
      background-position-x: -1030px;
    }

    @media (min-width: 781px) {
      height: 80vh;
    }

    padding-bottom: 30px;
    background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (max-width: 780px) {
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin-top: 100px;
    margin-left: 40px;
    font-size: 43px;

    font-weight: bold;

    color: #2290C9;

    span {
      color: #EAE634;
    }

    @media (max-width: 780px) {
      margin: 0;
      padding: 0;
      margin-top: 100px;
      text-align: center;
    }
  }

  button {
    width: 350px;
    margin-left: 83px;
    margin-top: 30px;

    @media (max-width: 780px) {
      margin: 0;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    padding: 0;
    font-size: 38px;
  }

  form {
    width: 350px;
    text-align: center;
    background-color: #2290C9;
    animation: ${appearFromRight} 1s;
    border-radius: 10px;
    padding: 30px;

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }

    button {
      margin: 0;

      margin-top: 15px;
    }
  }

`;
