import styled, { keyframes } from 'styled-components';

import ContainerBackgroundImg from '../../assets/bgusinas.png';

export const Container = styled.div`
    display: flex;

    @media (max-width: 780px) {
      flex-direction: column;
    }
`;

export const Background = styled.div`
    @media (min-width: 781px) {
      display: flex;
      flex: 1;
      margin: 15px;

      justify-content: center;
      align-items: center;
    }

    @media (max-width: 780px) {
      visibility: hidden;
      width: 0;
    }
`;

export const BackgroundMobile = styled.div`
    @media (max-width: 780px) {
      display: flex;
      justify-content: center;
      align-items: center;

      flex: 1;

      img {
        width: 450px;
      }
    }

    @media (min-width: 781px) {
      visibility: hidden;
      width: 0;
    }
`;

export const Content = styled.div`
  @media (min-width: 781px) {
  flex: 1;
    min-width: 600px;
  }

  background: url(${ContainerBackgroundImg}) no-repeat center;
  background-size: cover;
  background-position-x: -350px;

  display: flex;
  flex-direction: column;

  @media (max-width: 780px) {
    height: 300px;
    background-position-x: 0;
  }

  h1 {
    margin-top: 60px;
    margin-left: 70px;
    font-size: 30px;
    font-weight: bold;

    color: #FFF;
  }
`;

export const Place = styled.div`
  margin-left: 100px;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;

  justify-content: space-between;
  align-items: center;

  animation: ${appearFromRight} 1s;
`;
