/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, {
  ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import Swal from 'sweetalert2';
import {
  FiArrowLeft, FiMail, FiUser, FiPhone, FiSmartphone,
  FiDollarSign, FiPower, FiUsers, FiList, FiHome, FiFile,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import {
  Container, Content, AnimationContainer,
} from './styles';

import logoImg from '../../assets/logo.png';

import Input from '../../components/input';
import Button from '../../components/button';
import Select from '../../components/select';

import { useToast } from '../../hooks/Toast';

import libertyIcon from '../../assets/icon-liberdade.png';
import clearEnergyIcon from '../../assets/icon energia limpa.png';
import economyIcon from '../../assets/iconeeconomia.png';

interface SignUpFormData {
  name: string;
  surname: string;
  email: string;
  password: string;
  monthly_value: string;
  whatsapp: string;
  person_type: string;
  kind: string;
  document: string;
  phone: string;
  trading_name: string;
  documentfile: any;
}

const NeedProposal: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const [formData, _setFormData] = useState<FormData>(new FormData());

  const { id } = useParams() as { id: string; };

  const { addToast } = useToast();

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        formData.set(
          'documentfile',
          e.target.files[0],
          e.target.files[0].name,
        );
      }
    },
    [formData],
  );

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        surname: Yup.string().required('Sobrenome obrigatório'),
        email: Yup.string().required('Email obrigatório').email('Digite um e-mail válido'),
        phone: Yup.string().required('Telefone obrigatório'),
        monthly_value: Yup.string().required('Valor mensal obrigatório'),
        trading_name: Yup.string().required('Nome da empresa é obrigatório'),
        document: Yup.string().required('CNPJ da empresa é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formData.set('name', data.name);
      formData.set('surname', data.surname);
      formData.set('email', data.email);
      formData.set('phone', data.phone);
      formData.set('monthly_value', data.monthly_value);
      formData.set('whatsapp', data.whatsapp);
      formData.set('person_type', data.person_type);
      formData.set('kind', data.kind);
      formData.set('document', data.document);
      formData.set('trading_name', data.trading_name);

      await api.post('/proposals', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire('Proposta solicitada com sucesso! Em breve um dos nossos consultores entrará em contato.', '', 'success');
      history.push('/');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    }
  }, [addToast, formData, history]);

  const handleLoadProposal = useCallback(async (): Promise<void> => {
    const analisis = await api.get(`/simulations/process/${id}`);

    const { simulation } = analisis.data;

    formRef.current?.setData({
      name: simulation.name,
      surname: simulation.surname,
      email: simulation.email,
      phone: simulation.phone,
      whatsapp: simulation.whatsapp,
      person_type: simulation.person_type,
      kind: simulation.kind,
      monthly_value: simulation.monthly_value,
    });
  }, [id]);

  useEffect(() => {
    handleLoadProposal();
  }, [handleLoadProposal]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <div style={{
            display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <div>
              <img src={libertyIcon} alt="icon" className="img-fluid" />
              <h1 style={{ fontSize: 18, textAlign: 'center', marginTop: 5 }}>
                LIBERDADE DE ESCOLHA DA
                <br />
                ENERGIA DA SUA EMPRESA
              </h1>
            </div>
            <div>
              <img src={clearEnergyIcon} alt="icon" className="img-fluid" />
              <h1 style={{ fontSize: 18, textAlign: 'center', marginTop: 5 }}>
                LIBERDADE DE ESCOLHA DA
                <br />
                ENERGIA DA SUA EMPRESA
              </h1>
            </div>
            <div>
              <img src={economyIcon} alt="icon" className="img-fluid" />
              <h1 style={{ fontSize: 18, textAlign: 'center', marginTop: 5 }}>
                LIBERDADE DE ESCOLHA DA
                <br />
                ENERGIA DA SUA EMPRESA
              </h1>
            </div>
          </div>
          <Form onSubmit={handleSubmit} autoComplete="off" ref={formRef} id="#proposalForm">
            <h1 style={{ textAlign: 'center', fontSize: 30, marginTop: 18 }}>
              COMECE A ECONOMIZAR
              <br />
              CONSUMINDO ENERGIA LIMPA!
            </h1>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10,
            }}
            >
              <div style={{ width: '31%' }}>
                <Input name="name" icon={FiUser} placeholder="Nome" style={{ height: 27 }} />
              </div>
              <div style={{ width: '31%' }}>
                <Input name="surname" icon={FiUser} placeholder="Sobrenome" style={{ height: 27 }} />
              </div>
              <div style={{ width: '31%' }}>
                <Input name="email" icon={FiMail} placeholder="E-mail" style={{ height: 27 }} />
              </div>
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10,
            }}
            >
              <div style={{ width: '31%' }}>
                <Input name="phone" icon={FiPhone} mask="(99) 99999-9999" placeholder="Telefone" style={{ height: 27 }} />
              </div>
              <div style={{ width: '31%' }}>
                <Input name="whatsapp" icon={FiSmartphone} mask="(99) 99999-9999" placeholder="Whatsapp" style={{ height: 27 }} />
              </div>
              <div style={{ width: '31%' }}>
                <Input name="monthly_value" icon={FiDollarSign} placeholder="Valor mensal" style={{ height: 27 }} />
              </div>
            </div>

            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10,
            }}
            >
              <div style={{ width: '31%' }}>
                <Input name="document" icon={FiList} mask="99.999.999/9999-99" placeholder="CNPJ" style={{ height: 27 }} />
              </div>
              <div style={{ width: '31%' }}>
                <Input name="trading_name" icon={FiHome} placeholder="Nome da Empresa" style={{ height: 27 }} />
              </div>
              <div style={{ width: '31%' }}>
                <Input title="Anexar Conta de Energia" type="file" name="documentfile" onChange={handleFileChange} icon={FiFile} placeholder="Anexar Conta de Energia" style={{ height: 27 }} />
              </div>
            </div>

            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10,
            }}
            >
              <div style={{ width: '48%' }}>
                <Select title="Pessoa" id="person_type" name="person_type" icon={FiUsers} placeholder="Tipo de pessoa">
                  <option value="1">Jurídica</option>
                  <option value="2">Física</option>
                </Select>
              </div>

              <div style={{ width: '48%' }}>
                <Select title="R$ / kWh" name="kind" icon={FiPower} placeholder="R$ / kWh">
                  <option value="valor">R$</option>
                </Select>

              </div>
            </div>
            <Button type="submit" style={{ marginTop: 15, width: 400 }}>Solicitar Proposta</Button>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default NeedProposal;
