/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  InputHTMLAttributes, useEffect, useRef, useState, useCallback,
} from 'react';
import InputMask from 'react-input-mask';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    title?: string;
    icon?: React.ComponentType<IconBaseProps>;
    mask?: any;
}

const Input: React.FC<InputProps> = ({
  name, icon: Icon, title, mask, ...rest
}) => {
  const inputRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <>
      {title && <div style={{ textAlign: 'left', marginTop: '15px', marginBottom: '5px' }}>{title}</div>}
      <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
        {Icon && <Icon size={20} />}
        <InputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          autoComplete="new-password"
          defaultValue={defaultValue}
          ref={inputRef}
          mask={mask}
          {...rest}
        />

        {error
          && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
          )}
      </Container>
    </>
  );
};

export default Input;
