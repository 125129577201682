import styled, { keyframes } from 'styled-components';

import singUpBackgroundImg from '../../assets/bg-economia.png';

export const Container = styled.div`
    @media (min-width: 781px) {
      height: 600px;
    }

    background: url(${singUpBackgroundImg}) no-repeat center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 30px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h1 {
    margin-top: 30px;
    margin-left: 50px;
    font-size: 35px;

    @media (max-width: 780px) {
      font-size: 30px;
    }

    font-weight: bold;

    color: #2290C9;

    span {
      font-size: 28px;
      color: #fff;

      @media (max-width: 780px) {
        font-size: 25px;
      }
    }
  }
`;

export const Place = styled.div`
  width: 100%;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 15px;

  animation: ${appearFromRight} 1s;

  div {
    display: flex;
    flex-direction: row;

    @media (max-width: 780px) {
      flex-direction: column;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      font-weight: 520;

      font-size: 20px;

      flex: 1;

      color: #2290C9;
    }
  }
`;
