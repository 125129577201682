import React from 'react';
import { useTransition } from 'react-spring';

import { Container } from './styles';

import { ToastMessage } from '../../hooks/Toast';

import Toast from './Toast';

interface ContainerProps {
  messages: ToastMessage[],
}

const ToastContainer: React.FC<ContainerProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    {
      from: { right: '-120%', opacity: 0 },
      enter: { right: '0%', opacity: 1 },
      leave: { right: '-120%', opacity: 0 },
    },
  );

  return (
    <Container>
      {messagesWithTransitions((props, message, item) => (
        <Toast
          key={item.key}
          style={props}
          message={message}
        />
      ))}
    </Container>
  );
};

export default ToastContainer;
