import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 70px;
  padding-left: 70px;

  flex-direction: row;
  align-items: center;

  background: #FECD38 no-repeat center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 780px) {
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font-size: 33px;
    font-weight: bold;
    color: #FFF;

    @media (max-width: 780px) {
      text-align: center;
      margin-bottom: 15px;
    }
  }

  div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const Down = styled.div`
  display: flex;

  @media (min-width: 781px) {
    flex-direction: row;
    justify-content: end;
  }

  @media (max-width: 780px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  padding: 20px 5px;

  background: #2290C9 no-repeat center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    margin-top: 20px;

    @media (min-width: 781px) {
      margin-left: 70px;
      font-size: 23px;
    }

    @media (max-width: 780px) {
      text-align: center;
    }

    font-weight: bold;
    line-height: 1;

    color: #FFF;
  }

  @media (min-width: 781px) {
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .correcao {
    @media (max-width: 780px) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }
  }

  .ajuste {
    @media (max-width: 780px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      flex: 1;
      width: 100%;
      margin-bottom: 30px;

      div {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
