/* eslint-disable react/no-children-prop */
import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SinglePage from '../pages/SinglePage';
import Proposal from '../pages/Proposal';
import WhoWeAre from '../pages/WhoWeAre';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SinglePage} />
    <Route path="/proposal/:id" component={Proposal} />
    <Route path="/whoweare" component={WhoWeAre} />
  </Switch>
);

export default Routes;
