import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import {
  Container, Top, Down,
} from './styles';

import MyButton from '../../components/button';
import MyYellowButton from '../../components/yellowButton';

import economyFooter from '../../assets/economiarodape.png';
import logoFooter from '../../assets/logorodape.png';

import iconFacebook from '../../assets/iconface.png';
import iconInstagram from '../../assets/iconinsta.png';
import iconLinkedIn from '../../assets/iconLinkedIn.png';
import iconYoutube from '../../assets/iconYoutube.png';
import iconEmail from '../../assets/iconemail.png';

const Motivation: React.FC = () => {
  const [modal, setModal] = useState(false);

  const history = useHistory();

  const handleRollTo = useCallback((link: string) => {
    history.push('/');

    setTimeout(() => {
      window.location.href = link;

      setTimeout(() => {
        window.scroll(0, window.scrollY - 90);
      }, 800);
    }, 800);
  }, [history]);

  const toggle = useCallback(() => {
    handleRollTo('#simulation');
    // setModal(!modal);
  }, [handleRollTo]);

  return (
    <Container>
      {/* <Modal isOpen={modal}
        toggle={toggle} style={{ color: 'black', fontFamily: 'Montserrat, sans-serif' }}>
        <ModalHeader toggle={toggle} style={{ border: 'none', height: 15 }} />
        <h2 style={{ color: '#FECD38', padding: 15 }}>Entre em contato</h2>
        <div style={{ margin: 15 }}>
          <ModalBody>
            Em construção...
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Solicitar contato</Button>
            {' '}
            <Button color="secondary" onClick={toggle}>Cancelar</Button>
          </ModalFooter>
        </div>
      </Modal> */}
      <Top>
        <h1>
          QUER COMEÇAR A ECONOMIZAR?
        </h1>
        <div>
          <MyButton onClick={toggle} style={{ width: 180 }}>
            CONTATE-NOS
          </MyButton>
        </div>
      </Top>
      <Down>
        <div className="hiddem-mobile">
          <img src={economyFooter} alt="Economia" className="img img-fluid" />
        </div>
        <div className="correcao">
          <h1>
            Solicite-nos uma proposta e
            <br />
            <span style={{ fontSize: '20px' }}>comece a economizar agora mesmo!</span>
          </h1>
          <div className="hiddem-mobile">
            <MyYellowButton style={{ fontSize: '16px', height: 40, width: 250 }} onClick={() => { window.location.href = '/#simulation'; }}>SOLICITAR PROPOSTA</MyYellowButton>
          </div>

          <div className="hiddem-normal">
            <MyYellowButton style={{ fontSize: '16px', height: 60, width: 350 }} onClick={() => { window.location.href = '/#simulation'; }}>SOLICITAR PROPOSTA</MyYellowButton>
          </div>
        </div>
        <div className="ajuste">
          <div style={{ flex: 1 }}>
            <img src={logoFooter} alt="logo" width="200px" />
          </div>
          <div style={{
            display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'start', alignItems: 'start',
          }}
          >
            <a href="https://www.facebook.com/eabenergias">
              <img src={iconFacebook} className="img img-fluid" alt="logo" style={{ marginRight: 16 }} />
            </a>
            <a href="https://www.instagram.com/eabenergias">
              <img src={iconInstagram} className="img img-fluid" alt="logo" style={{ marginRight: 16 }} />
            </a>
            <a href="https://www.linkedin.com/company/77821906">
              <img src={iconLinkedIn} className="img img-fluid" alt="logo" style={{ marginRight: 16 }} />
            </a>
            <a href="https://www.youtube.com/channel/UC2d_8KBns7N6iU814kGu2Tg">
              <img src={iconYoutube} className="img img-fluid" alt="logo" style={{ marginRight: 16 }} />
            </a>
            <a href="mailto:comercial@eabenergias.com.br">
              <img src={iconEmail} className="img img-fluid" alt="logo" />
            </a>
          </div>
        </div>
      </Down>
      <Down style={{ height: 70, borderTop: '1px solid #fff' }}>
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 40,
        }}
        >
          Copyright © 2021 | EAB - Energias Alternativas do Brasil

        </div>
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center',
        }}
        >
          Políticas de Privacidade
        </div>
      </Down>
    </Container>
  );
};

export default Motivation;
