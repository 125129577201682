/* eslint-disable max-len */
import React from 'react';

import Header from '../../components/header';
import Footer from '../Footer';
import about2Img from '../../assets/imagemusinapaginaquemsomos.png';

const Home: React.FC = () => (
  <>
    <Header />
    {/* <Container>
      <Content>
        <h1>
          QUEM SOMOS?
        </h1>
      </Content>
    </Container> */}

    <br />
    <br />
    <br />

    <div className="container-fluid mt-3" style={{ padding: 5 }}>
      {/* <div className="row">
        <div className="col-6">
          <img src={aboutImg} className="img-fluid" alt="img" />
        </div>
        <div
          className="col-6"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30,
          }}
        >
          <h1 style={{
            color: '#2290C9', fontWeight: 'bold', textAlign: 'right',
          }}
          >
            QUEM SOMOS ?
          </h1>
          <div style={{
            fontSize: '20px', color: 'black', textAlign: 'justify', textIndent: 100,
          }}
          >
            <p>
              Somos uma empresa Norte Mineira com sede
              em Montes Claros. Situamos na região que é o
              coração da geração solar do estado de Minas
              Gerais, número 1 em Geração distribuída (GD) do
              Brasil.
            </p>
            <p>
              Em 2017 iniciamos nossas atividades com o
              objetivo de fornecer soluções alternativas de
              energia por meio de fontes limpas e renováveis, a
              fim de proporcionar liberdade de mercado,
              desenvolvimento sustentável e redução de custos
              para empresas.
            </p>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div
          className="col-6"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 30,
          }}
        >
          <h1 style={{
            color: '#2290C9', fontWeight: 'bold', textAlign: 'center',
          }}
          >
            NOSSAS FAZENDAS SOLARES
          </h1>
          <div style={{
            fontSize: '20px', color: 'black', textAlign: 'justify', textIndent: 100,
          }}
          >
            <p>
              Nossas usinas de geração de energia, também
              conhecidas como fazendas solares, estão
              instaladas no Norte e Noroeste de Minas Gerais.
              Estas regiões são privilegiadas com os maiores
              índices de irradiação solar do Brasil, o que nos
              permite uma produção de energia eficiente e
              segura.
            </p>
            <p>
              Por estarem interligadas à rede de distribuição
              da CEMIG, possuímos capacidade de atendimento
              em qualquer área de concessão em que a CEMIG
              atue. Desta forma, levamos solução sustentável de
              energia limpa e com economia a praticamente
              todo o Estado de Minas Gerais.
            </p>
          </div>
          <h1 style={{
            color: '#2290C9', fontWeight: 'bold', textAlign: 'center',
          }}
          >
            ESTAMOS CONSTRUINDO
            + 19 NOVAS USINAS !
          </h1>
        </div>
        <div className="col-6 mt-3 pt-3">
          <img src={about2Img} className="img-fluid" alt="img" />
        </div>
      </div>
      {/* <div className="row" style={{ height: 500 }}>
        <div
          className="col-4"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'black',
          }}
        >
          <img src={missionImg} className="img-fluid" alt="img" />
          <h1 style={{
            color: '#2290C9', fontWeight: 'bold', textAlign: 'center', marginTop: 10,
          }}
          >
            Missão
          </h1>
          <p style={{ marginTop: 8 }}>
            Desenvolver soluções alternativas de energias,
            de forma sustentável, afim de gerar resultados
            para clientes, colaboradores, parceiros, sócios
            e investidores.
          </p>
        </div>
        <div
          className="col-4"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'black',
          }}
        >
          <img src={visionImg} className="img-fluid" alt="img" />
          <h1 style={{
            color: '#2290C9', fontWeight: 'bold', textAlign: 'center', marginTop: 10,
          }}
          >
            Visão
          </h1>
          <p style={{ marginTop: 8 }}>
            Ser empresa referência no mercado de
            geração de energia.
          </p>
        </div>
        <div
          className="col-4"
          style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'black', marginTop: 80,
          }}
        >
          <img src={valuesImg} className="img-fluid" alt="img" />
          <h1 style={{
            color: '#2290C9', fontWeight: 'bold', textAlign: 'center', marginTop: 10,
          }}
          >
            Valores
          </h1>
          <p style={{ marginTop: 8, textAlign: 'left' }}>
            <ul>
              <li>
                Honestidade e Caráter
              </li>
              <li>
                Compromisso e Respeito
              </li>
              <li>
                Sustentabilidade
              </li>
              <li>
                Inovação
              </li>
              <li>
                Foco nos resultados
              </li>
            </ul>
          </p>
        </div>
      </div> */}
    </div>

    <Footer />
  </>
);

export default Home;
