import React from 'react';
import iconWhatsApp from '../../assets/iconwpp.png';
import { Container } from './styles';

const Fab: React.FC = () => (
  <Container>
    <a className="float" href="https://api.whatsapp.com/send?phone=5538997339327">
      <img className="img img-fluid my-float" src={iconWhatsApp} alt="Whatsapp" />
    </a>
  </Container>
);

export default Fab;
