import React from 'react';

import {
  Container,
} from './styles';

import benefitsBackground from '../../assets/placa-image.png';

const SignUp: React.FC = () => (
  <Container className="container-fluid">
    <img className="img img-fluid" src={benefitsBackground} alt="img" />
  </Container>
);

export default SignUp;
