import styled, { keyframes } from 'styled-components';

import HowWorksBackgroundImg from '../../assets/bg-como-funciona.png';
import HowWorksMobileBackgroundImg from '../../assets/mobile/bg-como-funciona.png';

export const Container = styled.div`
  display: flex;

  @media (min-width: 781px) {
    height: 100vh;

    background: url(${HowWorksBackgroundImg}) no-repeat center;
    background-size: cover;
  }

  @media (max-width: 780px) {
    height: 1600px;
    background: url(${HowWorksMobileBackgroundImg}) no-repeat center;
    background-size: cover;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 50px;
    margin-left: 50px;
    font-size: 35px;

    font-weight: bold;

    color: #fff;

    @media (max-width: 780px) {
      margin-top: 30px;
      margin-left: 50px;
    }
  }

  div {
    flex: 1;
    display: flex;
    margin-bottom: 50px;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 780px) {
      justify-content: flex-end;
    }

    animation: ${appearFromRight} 1s;
  }
`;

export const Place = styled.div`
  width: 100%;

  div button {
    @media (min-width: 781px) {
      width: 200;
      font-size: 12px;
      height: 28px;
    }
  }
`;

export const VideoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;

  @media(min-width: 781px) {
    background-color: #509B35;
  }

  @media(max-width: 780px) {
    background-color: #009933;
  }

  padding-top: 15px;
  padding-bottom: 15px;
`;
