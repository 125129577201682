import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;



  background-repeat: no-repeat;
  background-size: cover;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const Place = styled.div`
  width: 100%;
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  flex: 1;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;

  justify-content: space-between;
  align-items: center;

  animation: ${appearFromRight} 1s;

  @media (max-width: 780px) {
    visibility: hidden;
    height: 0;
  }

  div {
    display: flex;
    margin-top: 20px;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      flex: 1;
      font-size: 20px;
      font-weight: bold;

      color: #2290C9;
    }

    img {
      margin-top: 16px;
      flex: 1;
    }
  }
`;

export const MobileContainer = styled.div`
  flex-direction: 'column';
  @media (min-width: 781px) {
    visibility: hidden;
    height: 0;
  }
`;
