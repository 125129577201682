import React, {
  InputHTMLAttributes, useEffect, useRef, useState, useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
    name: string;
    title?: string;
    icon?: React.ComponentType<IconBaseProps>;
}

const Select: React.FC<InputProps> = ({
  name, icon: Icon, title, children, ...rest
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <>
      {title && (
      <div style={{
        textAlign: 'left', marginTop: '15px', marginBottom: '5px', color: '#000',
      }}
      >
        {title}
      </div>
      )}
      <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
        {Icon && <Icon size={20} />}

        <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          autoComplete="new-password"
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        >
          {children}
        </select>

        {error
        && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
        )}
      </Container>
    </>
  );
};

export default Select;
