import React from 'react';

import {
  Container,
} from './styles';

import Header from '../../components/header';
// import Footer from '../Footer';

import missionImg from '../../assets/iconmissao.png';
import visionImg from '../../assets/iconvisao.png';
import valuesImg from '../../assets/iconvalores.png';

const Home: React.FC = () => (
  <>
    <Header />
    <Container id="whowearen">
      <div className="firstDiv" />
      <div className="middle-div">
        <div className="itens-area" style={{ paddingRight: 15, paddingLeft: 15 }}>
          <div>
            <img src={missionImg} alt="img" style={{ width: 80 }} />
            <h1 style={{
              color: '#2290C9', fontWeight: 'bold', fontSize: 20, marginBottom: 30,
            }}
            >
              Missão
            </h1>
            <p style={{
              color: 'black', fontSize: '12px', textAlign: 'center', paddingLeft: 15, paddingRight: 15,
            }}
            >
              Desenvolver soluções alternativas de energias,
              de forma sustentável, afim de gerar resultados
              para clientes, colaboradores, parceiros, sócios
              e investidores.
            </p>
          </div>

          <div>
            <img src={visionImg} alt="img" style={{ width: 80 }} />
            <h1 style={{
              color: '#2290C9', fontWeight: 'bold', fontSize: 20, marginBottom: 30,
            }}
            >
              Visão
            </h1>
            <p style={{
              color: 'black', fontSize: '12px', textAlign: 'center', paddingLeft: 15, paddingRight: 15,
            }}
            >
              Ser empresa referência no mercado de
              geração de energia.
            </p>
          </div>

          <div>
            <img src={valuesImg} alt="img" style={{ width: 80 }} />
            <h1 style={{
              color: '#2290C9', fontWeight: 'bold', fontSize: 20, marginBottom: 30,
            }}
            >
              Valores
            </h1>
            <p style={{
              marginTop: 8, textAlign: 'left', color: 'black', fontSize: '12px',
            }}
            >
              <ul>
                <li>
                  Honestidade e Caráter
                </li>
                <li>
                  Compromisso e Respeito
                </li>
                <li>
                  Sustentabilidade
                </li>
                <li>
                  Inovação
                </li>
                <li>
                  Foco nos resultados
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="secondDiv">
        <div className="itens-area background-area " style={{ flexDirection: 'column' }}>
          <h1 style={{
            color: '#FECD38', fontWeight: 'bold', textAlign: 'right', marginBottom: 54, paddingRight: 20,
          }}
          >
            QUEM SOMOS ?
          </h1>
          <div style={{
            fontSize: '16px', color: 'white', textAlign: 'justify', textIndent: 100, paddingRight: 20, paddingLeft: 150,
          }}
          >
            <p>
              Somos uma empresa Norte Mineira com sede
              em Montes Claros. Situamos na região que é o
              coração da geração solar do estado de Minas
              Gerais, número 1 em Geração distribuída (GD) do
              Brasil.
            </p>
            <p>
              Em 2017 iniciamos nossas atividades com o
              objetivo de fornecer soluções alternativas de
              energia por meio de fontes limpas e renováveis, a
              fim de proporcionar liberdade de mercado,
              desenvolvimento sustentável e redução de custos
              para empresas.
            </p>
          </div>
        </div>
      </div>
    </Container>
  </>
);

export default Home;
