import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
    background: #2290C9;
    height: 45px;
    border-radius: 22px;
    border: 0;
    padding: 0 16px;
    color: #fff;

    font-weight: bold;

    transition: background-color 0.2s;

    box-shadow: #0000005e 1px 5px 5px;

    &:hover {
        background: ${shade(0.2, '#2290C9')}
    }
`;
