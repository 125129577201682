import styled, { css } from 'styled-components';

import Tooltip from '../tooltip';

interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    background: #FFF;
    border-radius: 10px;
    padding: 8px;
    width: 100%;

    border: 2px solid #FFF;
    color: #000;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 8px;
    }

    ${(props) => props.isErrored && (css`
        color: #333;
        border-color: #c53030;
    `)}

    ${(props) => props.isFocused && (css`
        color: #333;
        border-color: #333;
    `)}

    ${(props) => props.isFilled && (css`
        color: #333;
        border-color: #FFF;
    `)}

    select {
        flex: 1;
        background: transparent;
        border: 0;
        color: #000;
        height: 15px;
        font-size: 14px;

        &::placeholder {
            color: #000;
        }
    }

    select option {
      margin: 5px;
      background-color: #FFF;
      font-size: 14px;
      border: 0;
    }

    svg {
        margin-right: 16px;
        width: 15px;
    }
`;

export const Error = styled(Tooltip)`
    height: 20px;

    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
