/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import proposalBackgroundImg from '../../assets/proposal/bg-simulação.png';

export const Container = styled.div`
    display: flex;

    margin-top: 96px;

    @media(min-width: 781px) {
      height: 80vh;
    }

    background: url(${proposalBackgroundImg}) no-repeat center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media(max-width: 780px) {
    flex-direction: column;
  }

  div {
    display: flex;
    flex-direction: column;
    flex: 1;

    justify-content: center;
    align-items: center;

    h1 {
      font-size: 22px;
      font-weight: bold;
      color: #2290C9;

      span {
        font-size: 16px;
      }
    }

    div {
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      background-color: #2290C9;

      @media(min-width: 781px){
        @media(max-width: 1780px) {
          img {
            max-width: 130px;
          }
        }
      }

      span {
        color: #EAE634;
        font-weight: bold;
      }
    }
  }
`;
