import styled from 'styled-components';

export const Container = styled.div`
    display: flex;

    display: flex;
    align-items: stretch;

    flex-direction: column;

    padding: 0 30px;

    h1 {
      font-size: 35px;
      font-weight: bold;

      margin-bottom: 30px;
      color: #2290C9;

      span {
        font-size: 33px;
        color: #fff;
      }
    }

    h2 {
      color: #444;
      font-size: 20px;
      margin-bottom: 40px;
    }
`;
