import styled from 'styled-components';

import whoWeAreBackgroundImg from '../../assets/novas/quem-somos.png';
import aboutImg from '../../assets/imagemquemsomos.png';

export const Container = styled.div`
    display: flex;
    margin-top: 93px;

    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: url(${whoWeAreBackgroundImg}) no-repeat right;
    background-repeat: no-repeat;
    background-size: 50% 100%;


    .firstDiv {
      flex: 3;
      height: 90vh;
      background: url(${aboutImg}) no-repeat center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .secondDiv {
      display: flex;
      flex-direction: row;
      flex: 2;
      height: 90vh;

      text-align: center;


      .itens-area {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 20px;
      }
    }

    .middle-div {
      display: flex;
      text-align: center;
      flex: 1;
      height: 90vh;
      justify-content: center;
      align-items: center;

      max-width: 300px;

      .itens-area {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h1 {
    margin-top: 150px;
    margin-left: 70px;
    font-size: 43px;

    font-weight: bold;

    color: #2290C9;

    span {
      color: #EAE634;
    }
  }
`;
