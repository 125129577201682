import React from 'react';

import {
  Container, Content, Place, AnimationContainer, MobileContainer,
} from './styles';

import AdesaoGratuita from '../../assets/adesaogratuita.png';
import SemFidelidade from '../../assets/semfidelidade.png';
import SemObras from '../../assets/semobras.png';
import SemBurocracia from '../../assets/semburocracia.png';
import Renovavel from '../../assets/renovavel.png';

// import oneImg from '../../assets/proposal/01.png';
// import twoImg from '../../assets/proposal/02.png';
// import treeImg from '../../assets/proposal/03.png';
// import fourImg from '../../assets/proposal/04.png';
// import fiveImg from '../../assets/proposal/05.png';

import beneficiosImg from '../../assets/novas/beneficios.png';

const Motivation: React.FC = () => (
  <Container>
    <Content>
      <Place>
        <AnimationContainer>
          <div>
            <img src={AdesaoGratuita} alt="Adesão gratuita" className="img img-fluid" style={{ maxWidth: '80%' }} />
          </div>
          <div>
            <img src={SemFidelidade} alt="Adesão gratuita" className="img img-fluid" style={{ maxWidth: '80%' }} />
          </div>
          <div>
            <img src={SemObras} alt="Adesão gratuita" className="img img-fluid" style={{ maxWidth: '80%' }} />
          </div>
          <div>

            <img src={SemBurocracia} alt="Adesão gratuita" className="img img-fluid" style={{ maxWidth: '80%' }} />
          </div>
          <div>

            <img src={Renovavel} alt="Adesão gratuita" className="img img-fluid" style={{ maxWidth: '80%' }} />
          </div>
        </AnimationContainer>

      </Place>
      <MobileContainer>
        <div className="row">
          <div className="container-fluid">
            <div style={{ textAlign: 'center', padding: 10 }}>
              <img className="img-fluid" src={beneficiosImg} alt="img" style={{ minWidth: 600 }} />
              {
              /* <img className="img-fluid" src={oneImg} alt="img" style={{ width: 200 }} />
              <img className="img-fluid" src={twoImg}
              alt="img" style={{ width: 200, marginLeft: 30 }} />
              <img className="img-fluid" src={treeImg}
              alt="img" style={{ width: 200, marginLeft: 30 }} />
            </div>
            <div style={{ textAlign: 'center', padding: 30 }}>
              <img className="img-fluid" src={fourImg} alt="img" style={{ marginRight: 15 }} />
              <img className="img-fluid" src={fiveImg} alt="img" style={{ marginLeft: 15 }} /> */}
            </div>
          </div>
        </div>
      </MobileContainer>
    </Content>
  </Container>
);

export default Motivation;
