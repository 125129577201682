import React, { useCallback } from 'react';
import { Route as ReactRoute, RouteProps as ReactRouterProps } from 'react-router-dom';

interface RouteProps extends ReactRouterProps {
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const handleRender = useCallback(() => (<Component />), [Component]);

  // const handleRollToHome = useCallback(() => {
  //   window.location.href = '#home';

  //   setTimeout(() => {
  //     window.scroll(0, -90);
  //   }, 100);
  // }, []);

  // useEffect(() => {
  //   handleRollToHome();
  // }, [handleRollToHome]);

  return (
    <ReactRoute {...rest} render={handleRender} />
  );
};

export default Route;
