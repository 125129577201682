import React, { useCallback, useRef } from 'react';
import {
  FiMail, FiUser, FiPhone, FiSmartphone, FiDollarSign, FiPower, FiUsers,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import {
  Container, Content, AnimationContainer,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import api from '../../services/api';

import Input from '../../components/input';
import BlueButton from '../../components/button';
import Button from '../../components/yellowButton';
import Select from '../../components/select';

import { useToast } from '../../hooks/Toast';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const Home: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const history = useHistory();

  const handleSubmit = useCallback(async (data: SignUpFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        surname: Yup.string().required('Sobrenome obrigatório'),
        email: Yup.string().required('Email obrigatório').email('Digite um e-mail válido'),
        phone: Yup.string().required('Telefone obrigatório'),
        monthly_value: Yup.string().required('Valor mensal obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post('/simulations', data);

      const simulation = response.data;

      history.push(`/proposal/${simulation.id}`);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Verifique as informações e tente novamente.',
        });
      }
    }
  }, [addToast, history]);

  return (
    <>
      <Container>
        <Content>
          <h1>
            ENERGIA LIMPA
            <br />
            PARA A SUA EMPRESA
            <br />
            ATÉ

            <span> 20% </span>

            DE ECONOMIA
          </h1>

          <a href="#simulation" style={{ maxWidth: 350 }}>
            <BlueButton onClick={() => history.push('/')}>
              CALCULE SUA ECONOMIA
            </BlueButton>
          </a>

          <div className="hiddem-normal">
            <AnimationContainer>
              <h1>
                SIMULE A ECONOMIA
                DA SUA EMPRESA

              </h1>
              <Form onSubmit={handleSubmit} autoComplete="off" ref={formRef}>
                <Input name="name" icon={FiUser} placeholder="Nome" />
                <Input name="surname" icon={FiUser} placeholder="Sobrenome" />
                <Input name="email" icon={FiMail} placeholder="E-mail" />
                <Input name="phone" mask="(99) 99999-9999" icon={FiPhone} placeholder="Telefone" />
                <Input name="whatsapp" mask="(99) 99999-9999" icon={FiSmartphone} placeholder="Whatsapp" />

                <Select title="Pessoa" id="person_type" name="person_type" icon={FiUsers} placeholder="Tipo de pessoa">
                  <option value="1">Jurídica</option>
                  <option value="2">Física</option>
                </Select>

                <Select title="Qual o valor médio de sua conta de luz?" name="kind" icon={FiPower} placeholder="R$">
                  <option value="valor">R$</option>
                </Select>

                <Input type="number" pattern="(d{3})([.])(d{2})" name="monthly_value" icon={FiDollarSign} placeholder="Valor mensal" />

                <Button
                  style={{
                    width: '293px', height: '40px', fontSize: '20px',
                  }}
                  type="submit"
                >
                  QUERO ECONOMIZAR
                </Button>
              </Form>
            </AnimationContainer>
          </div>

        </Content>
      </Container>
    </>
  );
};

export default Home;
