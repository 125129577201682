import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    #warning-message { display: none; }

    @media only screen and (orientation:landscape){
        #warning-message { display:none; }
    }

    body {
        background: #FFF;
        color: #FFF;
        -webkit-font-smothing: antialiased;
        overflow-x:hidden;
    }

    body, input, button {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }

    .container-fluid {
      margin: 0;
      padding: 0;
    }

    .hiddem-mobile {
      @media (max-width: 780px) {
        visibility: hidden;
        height: 0;
        width: 0;

        div {
          visibility: hidden;
          height: 0;
          width: 0;
        }
      }
    }

    .hiddem-normal {
      @media (min-width: 781px) {
        visibility: hidden;
        height: 0;
        width: 0;

        div {
          visibility: hidden;
          height: 0;
          width: 0;
        }
      }
    }
`;
