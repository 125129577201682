import React from 'react';

import {
  Container, Content, Place, AnimationContainer,
} from './styles';

import IconEnergiaLimpa from '../../assets/icon energia limpa.png';
import IconLiberdade from '../../assets/icon-liberdade.png';
import IconEconomia from '../../assets/iconeeconomia.png';

const Home: React.FC = () => (
  <Container>
    <Content>
      <h1>
        ECONOMIA PARA SUA EMPRESA,
        <br />
        BENEFÍCIOS PARA O MEIO AMBIENTE!
        <br />
        <span>por que contratar créditos de energia solar?</span>
      </h1>
      <Place>
        <AnimationContainer>
          <div>
            <div>
              <img src={IconLiberdade} className="img img-fluid" alt="EAB Energias" />
            </div>
            <div>
              <img src={IconEnergiaLimpa} className="img img-fluid" alt="EAB Energias" />
            </div>
            <div>
              <img src={IconEconomia} className="img img-fluid" alt="EAB Energias" />
            </div>
          </div>

        </AnimationContainer>
      </Place>
    </Content>
  </Container>
);

export default Home;
