/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { useParams } from 'react-router-dom';

import {
  Container, Content,
} from './styles';

import HeaderMenu from '../../components/header';
import Footer from '../Footer';
import NeedProposal from '../ProposalForm';
import Button from '../../components/yellowButton';
import oneImg from '../../assets/proposal/01.png';
import twoImg from '../../assets/proposal/02.png';
import treeImg from '../../assets/proposal/03.png';
import fourImg from '../../assets/proposal/04.png';
import fiveImg from '../../assets/proposal/05.png';
import api from '../../services/api';

const Home: React.FC = () => {
  const [analisis, setAnalisis] = useState<any>({});

  const { id } = useParams() as { id: string; };

  const myRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => {
    if (myRef.current) { myRef.current.scrollIntoView(); }
  };

  const handleLoadProposal = useCallback(async (): Promise<void> => {
    const response = await api.get(`/simulations/process/${id}`);

    setAnalisis(response.data);
  }, [id]);

  useEffect(() => {
    handleLoadProposal();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
  [handleLoadProposal]);

  return (
    <>
      <HeaderMenu />
      <Container>
        <Content>
          <div style={{ width: '100' }}>
            <h1>
              OLÁ,
              {' '}
              <span>{`${analisis.simulation?.name || ''} ${analisis.simulation?.surname || ''}`}</span>
              <br />
              ENCONTRAMOS O MELHOR
              <br />
              PLANO PARA VOCÊ!
            </h1>
            <div style={{ marginTop: 10 }}>
              <div style={{
                color: '#2290C9', width: '100%', backgroundColor: '#FFF', fontWeight: 'bold', borderEndEndRadius: 0, borderEndStartRadius: 0, padding: 15,
              }}
              >
                {analisis.plan || ''}
              </div>
              <div style={{
                flex: 1, color: '#FFF', width: '100%', fontSize: 15, textAlign: 'center', display: 'block', padding: 15,
              }}
              >
                Com este plano você garante
                até
                <br />
                <span>
                  {analisis.percentage || ''}
                  %
                </span>
                {' '}
                de desconto e economiza
                <br />
                aproximadamente
                {' '}
                <span>{`R$ ${((analisis.discount || 0) * 12).toFixed(2)}`}</span>
                {' '}
                por ano.
                <div style={{ width: '100%' }}>

                  <Button
                    onClick={executeScroll}
                    style={{ fontSize: 14 }}
                  >
                    SOLICITAR PROPOSTA

                  </Button>

                </div>
              </div>
            </div>
          </div>
          <div style={{ flexDirection: 'column', backgroundColor: 'rgb(0,0,0,0)', padding: 30 }}>
            <div
              style={{
                flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(0,0,0,0)', padding: 30,
              }}
            >
              <img className="img-fluid" src={oneImg} alt="img" style={{ width: 200 }} />
              <img className="img-fluid" src={twoImg} alt="img" style={{ width: 200, marginLeft: 30 }} />
              <img className="img-fluid" src={treeImg} alt="img" style={{ width: 200, marginLeft: 30 }} />
            </div>
            <div
              style={{
                flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(0,0,0,0)', marginTop: 10,
              }}
            >
              <img className="img-fluid" src={fourImg} alt="img" style={{ width: 200 }} />
              <img className="img-fluid" src={fiveImg} alt="img" style={{ width: 200, marginLeft: 30 }} />
            </div>
          </div>
        </Content>
      </Container>
      <div ref={myRef}>
        <NeedProposal />
      </div>
      <Footer />
    </>
  );
};

export default Home;
