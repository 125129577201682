import questionIcon from '../assets/iconsinterrogacao.png';

interface IFaq {
  question: string;
  response: string;
  icon: string;
}

export const firstPage: IFaq[] = [
  {
    question: 'Quem pode participar?',
    response: `
      Ser da modalidade tarifária do Grupo B3;
      <br />
      Ter a conta da CEMIG cadastrada em um CNPJ;
      <br />
      Obs.: Planos para pessoas físicas serão disponibilizados futuramente.
    `,
    icon: questionIcon,
  },
  {
    question: 'Quem é MEI ou Empresário Individual pode participar?',
    response: `
      Sim! Pela determinação da Junta Comercial de Minas Gerais (JUCEMG)
      de 15 de dezembro de 2020 quem é Empresário Individual ou
      <br />
      MEI está apto a participar do consórcio de geração
      distribuída de energia elétrica.
    `,
    icon: questionIcon,
  },
  // {
  //   question: 'Tarifas Branca, Rural e Social podem participar?',
  //   response: `
  //     Não atendemos a essas modalidades tarifárias.
  //   `,
  //   icon: questionIcon,
  // },
  // {
  //   question: 'O que é Modalidade Tarifária?',
  //   response: `
  //     As modalidades tarifárias são um conjunto de tarifas aplicáveis ao consumo
  //     de energia elétrica e demanda de potência da unidade consumidora.
  //     <br />
  //     Elas são definidas de acordo com o Grupo Tarifário:
  //     <br />
  //     Grupo A: consumidores atendidos em média ou alta tensão, acima de 2,3 kV.
  //     Geralmente, os consumidores desta modalidade são
  //     <br />
  //     empresas de médio ou grande porte como supermercados, hospitais,
  //     shoppings e que podem ser divididos em subgrupos de tensão;
  //     <br />
  //     Grupo B: consumidores atendidos em baixa tensão, abaixo de 2,3 kV.
  //     Geralmente é nesse grupoque empresas de pequeno porte se
  //     <br />
  //     enquadram e que estão aptas a utilizar
  //     do sistema de geração distribuída da EAB.
  //   `,
  //   icon: questionIcon,
  // },
  // {
  //   question: 'Pessoa Física pode participar?',
  //   response: `
  //     Nossos planos estão disponíveis apenas para pessoas jurídicas mas,
  //     em breve, vamos disponibilizá-los para pessoas físicas também,
  //     <br />
  //     então fique ligado por aqui.
  //   `,
  //   icon: questionIcon,
  // },
  {
    question: 'Por que tenho que participar de um consórcio?',
    response: `
      Essa é uma condição prevista na Portaria 482/2012 da ANEEL,
      que regulamenta os sistemas de distribuição de energia elétrica.
    `,
    icon: questionIcon,
  },
];

export const secondPage: IFaq[] = [
  {
    question: 'Qual será o meu desconto?',
    response: `
      O desconto proporcionado pela EAB pode chegar a até 20% sobre a tarifa
      compensável da CEMIG, ou seja, a tarifa base mais o ICMS. Em um ano,
      isso representa
      cerca de duas contas de luz a menos.
    `,
    icon: questionIcon,
  },
  // {
  //   question: 'Possui fidelidade? ',
  //   response: `
  //     Não há fidelidade para o nosso plano, porém, em caso de desistência,
  //     precisamos ser avisados com antecedência de 90 dias. Durante esse período,
  //     você continua recebendo a sua economia normalmente.
  //   `,
  //   icon: questionIcon,
  // },
  {
    question: 'Vou continuar sendo cliente da CEMIG?',
    response: `
      Sim, porém, ao invés de receber a conta da CEMIG, você recebe
      uma fatura da EAB com o desconto aplicado e as informações
      discriminadas, tudo de maneira bem clara e simples.
    `,
    icon: questionIcon,
  },
  // {
  //   question: 'Por que se ligar na EAB?',
  //   response: `
  //     Quando sua empresa se liga aqui, você garante até 20%
  //     de desconto em sua conta de luz. Isso representa cerca
  //     de 2 contas a menos por ano.
  //     <br />
  //     Além disso, você contribui com a geração de energia limpa
  //     e sustentável, de um jeito descomplicado, seguro,
  //     sem fidelidade, instalações ou qualquer tipo de investimento.
  //   `,
  //   icon: questionIcon,
  // },
  {
    question: 'O que é custo de disponibilidade?',
    response: `
      É a taxa cobrada pela CEMIG para disponibilizar a eletricidade.
      Refere-se ao custo mínimo da sua fatura, ainda que você não
      consuma nada no mês.
    `,
    icon: questionIcon,
  },
  {
    question: 'Qual é a tarifa compensável da EAB?',
    response: `
      O seu custo na CEMIG é composto pela soma da tarifa base,
      taxa de contribuição de iluminação pública municipal, ICMS
      e PIS/COFINS. Nós aplicamos o desconto de até 20% sobre a
      tarifa base e o ICMS. O PIS/COFINS e a taxa de contribuição
      de iluminação pública não entram na compensação, pois são
      impostos federais e municipal, respectivamente.
    `,
    icon: questionIcon,
  },
  {
    question: 'O que é a Contribuição de iluminação pública?',
    response: `
      A Contribuição de iluminação pública é uma taxa obrigatória,
      cobrada pela concessionária de energia local, que está
      estabelecida no art.149-A da Constituição Federal.
    `,
    icon: questionIcon,
  },
  {
    question: 'Como faço para cancelar a contratação?',
    response: 'O Contrato poderá ser rescindido pela contratante mediante comunicação no prazo indicado no Plano de Contratação. O contratante deverá arcar com multa, caso possua.',
    icon: questionIcon,
  },
  {
    question: 'Em quais regiões a EAB atua?',
    response: `
      No momento, a EAB atua somente em Minas Gerais,
      nos municípios atendidos pela CEMIG.
    `,
    icon: questionIcon,
  },
];

export const thirdPage: IFaq[] = [
  {
    question: 'O que é energia injetada?',
    response: `
      A energia injetada é toda energia gerada pelo sistema de geração distribuída e transmitida para a subestação mais próxima da concessionária local. Essa energia é transformada em créditos que são compensados na sua conta de luz.
    `,
    icon: questionIcon,
  },
  {
    question: 'Como o valor da cota é calculado?',
    response: `
      Para dimensionar a sua cota, começamos calculando a sua média anual de consumo na CEMIG. A partir daí, definimos qual a quantidade de créditos de energia necessária para compensar o seu consumo. Nosso desconto é aplicado sobre a tarifa vigente da CEMIG, ou seja, sobre os créditos de energia que são injetados para a sua empresa. Como a incidência solar varia ao longo dos meses e estações do ano, ocorre também uma variação da injeção de créditos para mais ou para menos em relação à sua cota contratada, mas não se preocupe! Pelo sistema de compensação, quando a injeção é maior que a sua cota contratada, você acumula créditos. Esses créditos, então, poderão ser utilizados em até 60 meses, sempre que a injeção for menor que a sua cota contratada. Dessa forma, a EAB garante o desconto contratado e você economiza até 2 contas de luz ao ano.
    `,
    icon: questionIcon,
  },
  // {
  //   question: 'Como a EAB consegue ter lucro?',
  //   response: `
  //     Majoritariamente, o Brasil consome energia elétrica proveniente das grandes hidrelétricas,
  //     porém, nos últimos anos, houve um movimento importante no mercado de Geração Distribuída
  //     que potencializou a vertente energética renovável no país, incentivando a construção de
  //     novas usinas.Por se tratar de uma modalidade pela qual conseguimos gerar créditos de
  //     energia a partir de uma fonte limpa e renovável próxima ao local de consumo, o que
  //     diminui significativamente os custos com a transmissão de energia e as perdas decorrentes
  //     desse processo, é possível ofertar a compensação da energia aos nossos clientes a
  //     um custo mais baixo.
  //   `,
  //   icon: questionIcon,
  // },
  // {
  //   question: 'Se estiver pouco sol, o desconto diminui?',
  //   response: `
  //     Nossas fazendas solares estão localizadas estrategicamente em uma região de altíssima
  //     incidência solar durante todo o ano no norte de Minas. Portanto, não se preocupe caso
  //     nosso estado esteja passando por dias nublados ou chuvosos, pois a gente já planejou
  //     isso por aqui também. Pelo sistema de compensação, quando a injeção de energia é maior
  //     que a sua cota contratada, você acumula créditos. Esses créditos, então, poderão ser
  //     utilizados em até 60 meses, sempre que a injeção for menor que a sua cota contratada.
  //     Ou seja, mesmo com variações climáticas e mudança das estações, nós garantimos a sua
  //     economia de até 20% sobre a tarifa da CEMIG, o que representa cerca de duas contas de
  //     luz a menos no ano, com toda segurança, simplicidade e transparência que só a EAB oferece.
  //   `,
  //   icon: questionIcon,
  // },
  {
    question: 'E se a tarifa da CEMIG aumentar?',
    response: `
      Nossos descontos também se aplicam sobre o aumento. Somente no ano de 2020, de acordo com a Agência Nacional de Energia Elétrica (ANEEL), houve um aumento médio de 4,3% para os consumidores, ao invés da redução média de 0,5% calculada inicialmente. A gente sabe o quanto isso pesa no seu bolso, então conte conosco para estar ao lado da sua empresa, garantindo proteção e economia para você.
    `,
    icon: questionIcon,
  },
  // {
  //   question: 'A EAB é um serviço ou um aluguel de cota solar?',
  //   response: `
  //     Somos uma empresa mineira que permite, por meio de um contrato de locação
  //     sem fidelidade, que nossos clientes se beneficiem de um sistema de geração
  //     distribuída de energia limpa e econômica.
  //   `,
  //   icon: questionIcon,
  // },
  {
    question: 'Precisa fazer alguma obra ou instalação física?',
    response: `
      Não! Para usufruir do sistema de geração distribuída não é necessário fazer nenhum tipo de instalação na sua empresa, pois a sua energia continua vindo da CEMIG. A única diferença é que ao invés de continuar recebendo a sua conta da CEMIG da forma tradicional, você receberá uma fatura da EAB com o desconto aplicado e as informações discriminadas, tudo de maneira bem clara e simples.
    `,
    icon: questionIcon,
  },
  {
    question: 'Como fica a minha relação com a CEMIG?',
    response: `
      A CEMIG continua sendo responsável por fornecer a sua energia e te atender em qualquer falha na rede elétrica. Mas, ao alugar uma de nossas cotas, sua empresa utilizará créditos de energia injetados pela EAB na rede da CEMIG.
    `,
    icon: questionIcon,
  },
];
