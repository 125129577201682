import styled from 'styled-components';

import whoWeAreBackgroundImg from '../../assets/novas/quem-somos.png';

export const Container = styled.div`
    background: url(${whoWeAreBackgroundImg}) no-repeat center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -90px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h1 {
    margin-top: 10px;
    font-size: 43px;

    font-weight: bold;

    color: #2290C9;

    span {
      color: #EAE634;
    }
  }
`;
