/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Collapse, Button,
} from 'reactstrap';

import { firstPage, secondPage, thirdPage } from '../../mockups/faqs';

import {
  Container,
} from './styles';

import faqIcon from '../../assets/icon-faq.png';

const SignUp: React.FC = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [isOpen, setIsOpen] = useState<any>([]);

  const toggle = useCallback((tab: any) => {
    setIsOpen([]);
    if (activeTab !== tab) setActiveTab(tab);
  }, [activeTab]);

  const toggleAsk = useCallback((index: number) => {
    const isOpenCopy = [...isOpen];
    isOpenCopy[index] = !isOpenCopy[index];
    setIsOpen(isOpenCopy);
  }, [isOpen]);

  return (
    <>
      <Container id="faqs">
        <h2 style={{ textAlign: 'center' }}>*Aviso prévio de 120 dias para desvinculação da compensação de energia.</h2>
        <h1>DÚVIDAS FREQUENTES</h1>
        <div>
          <Nav
            tabs
            style={{
              fontSize: '16px', fontWeight: 'bold',
            }}
          >
            <NavItem style={{ width: '45%' }}>
              <NavLink
                style={activeTab === '1' ? {
                  backgroundColor: '#2290C9', color: '#fff', borderStartStartRadius: '30px', borderStartEndRadius: '30px', border: 0, padding: '20px',
                } : {
                  backgroundColor: '#80b3cc', color: '#fff', borderStartStartRadius: '30px', borderStartEndRadius: '30px', border: 0, padding: '20px',
                }}
                onClick={() => { toggle('1'); }}
              >
                QUAIS SÃO OS CRITÉRIOS
                PARA PARTICIPAR?
              </NavLink>
            </NavItem>
            <NavItem style={{ width: '20%' }}>
              <NavLink
                style={activeTab === '2' ? {
                  backgroundColor: '#2290C9', color: '#fff', borderStartStartRadius: '30px', borderStartEndRadius: '30px', border: 0, padding: '20px',
                } : {
                  backgroundColor: '#80b3cc', color: '#fff', borderStartStartRadius: '30px', borderStartEndRadius: '30px', border: 0, padding: '20px',
                }}
                onClick={() => { toggle('2'); }}
              >
                COMO FUNCIONA?
              </NavLink>
            </NavItem>
            <NavItem style={{ width: '35%' }}>
              <NavLink
                style={activeTab === '3' ? {
                  backgroundColor: '#2290C9', color: '#fff', borderStartStartRadius: '30px', borderStartEndRadius: '30px', border: 0, padding: '20px',
                } : {
                  backgroundColor: '#80b3cc', color: '#fff', borderStartStartRadius: '30px', borderStartEndRadius: '30px', border: 0, padding: '20px',
                }}
                onClick={() => { toggle('3'); }}
              >
                O QUE É GERAÇÃO
                DISTRIBUÍDA?
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            style={{
              backgroundColor: '#2290C9', color: '#fff', fontSize: '15px', paddingLeft: 20, padding: 25, borderTop: '1px dashed #fff', marginBottom: 50,
            }}
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  {firstPage.map((item, index) => (
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={item.icon} alt="Porque?" />
                      </div>

                      <span style={{ flex: 1, marginLeft: 5 }}>
                        <Button color="light" outline style={{ border: 0 }} onClick={() => toggleAsk(index)}>
                          {item.question}
                        </Button>

                        <br />
                        <Collapse isOpen={isOpen[index]}>
                          <span dangerouslySetInnerHTML={{ __html: item.response }} />
                        </Collapse>
                      </span>
                    </p>
                  ))}
                </Col>
              </Row>
              <img src={faqIcon} alt="FAQ" />
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  {secondPage.map((item, index) => (
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={item.icon} alt="Porque?" />
                      </div>

                      <span style={{ flex: 1, marginLeft: 5 }}>
                        <Button color="light" outline style={{ border: 0 }} onClick={() => toggleAsk(index)}>
                          {item.question}
                        </Button>

                        <br />
                        <Collapse isOpen={isOpen[index]}>
                          <span dangerouslySetInnerHTML={{ __html: item.response }} />
                        </Collapse>
                      </span>
                    </p>
                  ))}
                </Col>
              </Row>
              <img src={faqIcon} alt="FAQ" />
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  {thirdPage.map((item, index) => (
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={item.icon} alt="Porque?" />
                      </div>

                      <span style={{ flex: 1, marginLeft: 5 }}>
                        <Button color="light" outline style={{ border: 0 }} onClick={() => toggleAsk(index)}>
                          {item.question}
                        </Button>

                        <br />
                        <Collapse isOpen={isOpen[index]}>
                          <span dangerouslySetInnerHTML={{ __html: item.response }} />
                        </Collapse>
                      </span>
                    </p>
                  ))}
                </Col>
              </Row>
              <img src={faqIcon} alt="FAQ" />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </>
  );
};

export default SignUp;
